import "../styles/global.css";
import "public/dashboard_assets/static/scss/GlobalVendor.css";
import "@leapfinance/frontend-commons/tailwind-compiled.css";
import type { AppContext, AppProps } from "next/app";
import { Provider } from "react-redux";
import { store, wrapper } from "redux/store";
import { NextPageContext } from "next";
import { captureAndSaveUtm } from "@/utils/utmUtils";
import App from "next/app";
import { ParsedUrlQuery } from "querystring";
import { END } from "redux-saga";
import { getCookie, getTokenCookie } from "@/utils/cookieUtils";
import { AuthToken } from "@leapfinance/auth-kit";
import { authActions } from "@/component/Common/Login/redux/AuthActions";
import type { Store } from "@reduxjs/toolkit";
import GlobalModal from "@/component/Modals/GlobalModal";
import { ToastContainer } from "react-toastify";
import { getAuthSerialaisableObject } from "@/utils/authUtils";
import Script from "next/script";

interface Props {
  query: ParsedUrlQuery;
  err: Error;
}

declare global {
  interface Window {
    dataLayer: object;
  }
}

declare module "next" {
  export interface NextPageContext {
    /**
     * Provided by next-redux-wrapper: The redux store
     */
    store: Store;
  }
}

const IeltsDashboard = ({ Component, pageProps }: AppProps & Props) => {
  const isDev = process.env.NODE_ENV === "development";
  const userId = getCookie(null, "user_id");

  return (
    <>
      {!isDev && (
        <Script
          id="leap-tracker-clarity"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      console.log("in clarity", ${userId})
      clarity("set", "preUserId", "${userId}");
    })(window, document, "clarity", "script", '${process.env.NEXT_PUBLIC_CLARITY_KEY}');
  `,
          }}
        />
      )}
      <Script
        id="leap-tracker-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
                   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                   n.queue=[];t=b.createElement(e);t.async=!0;
                   t.src=v;s=b.getElementsByTagName(e)[0];
                   s.parentNode.insertBefore(t,s)}(window, document,'script',
                   'https://connect.facebook.net/en_US/fbevents.js');
                   fbq('init', ${process.env.PIXEL_KEY});
                   fbq('track', 'PageView');`,
        }}
      ></Script>
      <Provider store={store}>
        <Component {...pageProps} />
        <GlobalModal />
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover
        />
      </Provider>
    </>
  );
};

IeltsDashboard.getInitialProps = async (
  appContext: AppContext & NextPageContext,
) => {
  captureAndSaveUtm(appContext.ctx, appContext.ctx.query);
  const appProps = await App.getInitialProps(appContext);

  if (appProps.pageProps?.error?.statusCode && appContext.ctx.res) {
    appContext.ctx.res.statusCode = appProps.pageProps.error.statusCode;
  }

  if (appContext.ctx.req && appContext.Component.getInitialProps) {
    appContext.ctx.store.dispatch(END);
    await (appContext.ctx.store as any).sagaTask.toPromise();
  }

  const token = getTokenCookie(appContext.ctx);
  const auth = new AuthToken(token);
  appContext.ctx.store.dispatch(
    authActions.saveAuth(getAuthSerialaisableObject(token)),
  );
  appProps.pageProps.auth = auth;
  return {
    query: appContext.ctx.query,
    ...appProps,
  };
};

export default wrapper.withRedux(IeltsDashboard);
