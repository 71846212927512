import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CalendarView } from "@/component/Calendar/redux/demoTypes";
import {
  DemoDetails,
  SpotCounselingLiveCalendarResponseDto,
  SpotCounsellingCardDto,
} from "redux/services/Apitypes";
import { laCounsellingApi } from "redux/services/laCounsellingApi";
import { userInfoApi } from "redux/services/userInfoApi";
import { trackPixelEvent } from "@/utils/segmentUtils";
import { fireTagEvent } from "@/utils/ga";

const IeltsDemoSliceName = "IeltsDemo";

export interface IeltsDemoSchema {
  calendarView: CalendarView;
  ieltsSlotsDetails: SpotCounselingLiveCalendarResponseDto | null;
  slotsArray: Array<SpotCounsellingCardDto>;
  selectedSlot: { slot: SpotCounsellingCardDto | null; index: number } | null;
  error: boolean;
  hasBookedSlot: boolean;
  hasNoSlots: boolean;
  isLoading: boolean;
  demoDetails: null | DemoDetails;
  isStudentEligible: boolean | null;
}
const initialState: IeltsDemoSchema = {
  calendarView: CalendarView.LOADING,
  ieltsSlotsDetails: null,
  slotsArray: [],
  selectedSlot: null,
  error: false,
  hasBookedSlot: false,
  hasNoSlots: false,
  isLoading: true,
  demoDetails: null,
  isStudentEligible: null,
};

const ieltsDemoSlice = createSlice({
  name: IeltsDemoSliceName,
  initialState,
  reducers: {
    setCalendarView(state, action: PayloadAction<CalendarView>) {
      return { ...state, calendarView: action.payload };
    },
    setIeltsSlotsDetails(
      state,
      action: PayloadAction<SpotCounselingLiveCalendarResponseDto | null>,
    ) {
      return { ...state, ieltsSlotsDetails: action.payload };
    },
    setSlotsArray(state, action: PayloadAction<Array<SpotCounsellingCardDto>>) {
      return { ...state, slotsArray: action.payload };
    },
    setSelectedSlot(
      state,
      action: PayloadAction<{
        slot: SpotCounsellingCardDto;
        index: number;
      } | null>,
    ) {
      return { ...state, selectedSlot: action.payload };
    },
    setError(state, action: PayloadAction<boolean>) {
      return { ...state, error: action.payload };
    },
    setHasBookedSlot(state, action: PayloadAction<boolean>) {
      return { ...state, hasBookedSlot: action.payload };
    },
    setHasNoSlots(state, action: PayloadAction<boolean>) {
      return { ...state, hasNoSlots: action.payload };
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload };
    },
    setFormData(state, action: PayloadAction<Record<string, any>>) {
      return { ...state, formData: action.payload };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      laCounsellingApi.endpoints.getCounsellingSlots.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          if (
            payload.liveSlotAvailable ||
            payload.spotCounselingCalendarResponses?.length > 0
          ) {
            return {
              ...state,
              ieltsSlotsDetails: payload,
              isLoading: false,
            };
          } else {
            return { ...state, hasNoSlots: true, isLoading: false };
          }
        }
        return { ...state, error: true };
      },
    );
    builder.addMatcher(
      userInfoApi.endpoints.getStudentEligibility.matchFulfilled,
      (state, { payload }) => {
        if (payload.data === true) {
          trackPixelEvent("trackCustom", "LA_QL_Only");
          fireTagEvent("LA_Qualified_Lead");
        }
        if (payload.data === false) {
          return {
            ...state,
            isStudentEligible: !!payload.data,
            isLoading: false,
          };
        }
        return { ...state, isStudentEligible: !!payload.data };
      },
    );
    builder.addMatcher(
      userInfoApi.endpoints.getStudentEligibility.matchRejected,
      (state) => {
        return { ...state, isStudentEligible: false };
      },
    );

    builder.addMatcher(
      laCounsellingApi.endpoints.getCounsellingSlots.matchPending,
      (state) => {
        return { ...state, isLoading: true };
      },
    );

    builder.addMatcher(
      laCounsellingApi.endpoints.getCounsellingSlots.matchRejected,
      (state) => {
        return { ...state, error: true };
      },
    );

    builder.addMatcher(
      laCounsellingApi.endpoints.getBookedCounsellingSlots.matchFulfilled,
      (state, { payload }) => {
        if (payload && payload.length) {
          return {
            ...state,
            demoDetails: payload[0] as DemoDetails,
          };
        }
        return state;
      },
    );
  },
});

export const bookIeltsSlot = createAction<{
  payload: any;
  isReschedule: boolean;
  segmentPayload?: any;
  postBookingCallBack?: null | (() => void);
}>(`${IeltsDemoSliceName}/bookIeltsSlot`);

export const {
  setCalendarView,
  setIeltsSlotsDetails,
  setSlotsArray,
  setSelectedSlot,
  setError,
  setHasBookedSlot,
  setHasNoSlots,
  setIsLoading,
  setFormData,
} = ieltsDemoSlice.actions;

export default ieltsDemoSlice.reducer;
