import { combineReducers } from "redux";
import { dashboardRouterBaseApiWithTags } from "redux/services/base/dashboardRouter";
import { dashboardApiWithTags } from "redux/services/base/dashboardApi";
import { modalsSlice } from "@/component/Modals/modalSlice";
import AuthReducer from "@/component/Common/Login/redux/AuthReducer";
import FiltersSlice from "@/component/Calendar/Filters/redux/FiltersSlice";
import IeltsDemoSlice from "@/component/Calendar/redux/IeltsDemoSlice";

export const rootReducer = combineReducers({
  [dashboardRouterBaseApiWithTags.reducerPath]:
    dashboardRouterBaseApiWithTags.reducer,
  [dashboardApiWithTags.reducerPath]: dashboardApiWithTags.reducer,
  modals: modalsSlice.reducer,
  auth: AuthReducer,
  Filters: FiltersSlice,
  IeltsDemo: IeltsDemoSlice,
});
