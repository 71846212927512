import format from "date-fns/format";
import isValid from "date-fns/isValid";

export const cloneDeep = (data: any) => JSON.parse(JSON.stringify(data));

export const isFunction = (data: any) => typeof data === "function";
export const debounce = (callback: () => void, delay: number): (() => void) => {
  let timer: NodeJS.Timeout | null = null;
  return function (...args) {
    //@ts-ignore
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(context, args);
    }, delay);
  };
};

export const getObjectKeys = Object.keys as <T extends object>(
  obj: T,
) => Array<keyof T>;

export const formatDfns = (...rest: Parameters<typeof format>) => {
  if (isValid(rest[0])) {
    return format(...rest);
  }
  return "";
};
