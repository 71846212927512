export const NAV_ROUTES = {
  AUTH_PATH: "/api/leap-advantage/dashboard_auth?path=",
  LOGOUT: "/api/leap-advantage/dashboard_logout",
  ABOUT_US: "/about-us",
  TERM_AND_CONDITION: "/terms",
  REFUND_POLICY: "/refund-policy",
  LA_CALENDAR: "/la-counselling",
  ZOOM_DESKTOP: "https://zoom.us/download",
  USER_DETAILS_FORM: "la-counselling/user-details-form",
  IOS_ZOOM_APP:
    "https://apps.apple.com/in/app/zoom-one-platform-to-connect/id546505307?utm_source=website&utm_medium=iOS&utm_campaign=dashboard",
  ANDROID_ZOOM_APP:
    "https://play.google.com/store/apps/details?id=us.zoom.videomeetings&hl=en&gl=US",
};
